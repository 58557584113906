/************ Skandinavisk colors ***********/
/******* Aspect ratios *****/
/****** Transition *******/
.banner-two-col {
  background-color: var(--bg-color);
  display: flex;
  flex-flow: column;
}
@media only screen and (min-width: 768px) {
  .banner-two-col {
    align-items: stretch;
    flex-flow: row;
  }
}
@media only screen and (min-width: 768px) {
  .banner-two-col.image__left {
    flex-flow: row-reverse;
  }
}
@media only screen and (min-width: 768px) {
  .banner-two-col.image__right {
    flex-flow: row;
  }
}
@media only screen and (max-width: 767px) {
  .banner-two-col.image__top {
    flex-flow: column-reverse;
  }
}
@media only screen and (max-width: 767px) {
  .banner-two-col.image__bottom {
    flex-flow: column;
  }
}

@media only screen and (min-width: 768px) {
  .banner-two-col__tile {
    flex-basis: 50%;
    width: 50%;
  }
}

.banner-two-col__tile-content {
  height: 100%;
  width: 100%;
}