@import '../../pages/page-include';

.banner-two-col {
    background-color: var(--bg-color);
    display: flex;
    flex-flow: column;

    @include mq($not-mobile) {
        align-items: stretch;
        flex-flow: row;
    }

    &.image__left {
        @include mq($not-mobile) {
            flex-flow: row-reverse;
        }
    }

    &.image__right {
        @include mq($not-mobile) {
            flex-flow: row;
        }
    }

    &.image__top {
        @include mq($mobile-max) {
            flex-flow: column-reverse;
        }
    }

    &.image__bottom {
        @include mq($mobile-max) {
            flex-flow: column;
        }
    }
}

.banner-two-col__tile {
    @include mq($not-mobile) {
        flex-basis: 50%;
        width: 50%;
    }
}

.banner-two-col__tile-content {
    height: 100%;
    width: 100%;
}
